<template>
  <div class="mine">
    <pageHead :hide-back="true" title="我的" />
    <!--todo    个人信息和切换官方身份-->
    <info v-bind="userInfo" @click="handleAvatarClick" />
    <!--todo    去发贴-->
    <banner @click="handleCreateTopic" />
    <!-- todo   导航列表-->
    <navList @click="handleRoute" :options="navOptions" />
    <!--todo    选择修改头像或者修改头像框-->
    <van-action-sheet
      v-model="showActionSelect"
      :actions="actionsSelect"
      cancel-text="取消"
      close-on-click-action
      @select="handleSelect"
      get-container="#app"
    />
    <!--todo    修改头像-->
    <van-action-sheet
      v-model="showAction"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="handleAvatarSelect"
      get-container="#app"
    />
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import {selectFile} from "@/utils/selectImg";
import {SUPER_ADMIN_ID, ADMIN_NAME} from "@/config/config.js";
import {getUserById, updateAvatar} from "@/api/suser";

import info from "./components/info";
import navList from "./components/navList";
import banner from "./components/banner";

export default {
  components: {
    pageHead,
    info,
    navList,
    banner,
  },
  data () {
    const initImgUrl = (name) => {
      return `/images/mine/${name}`;
    };
    return {
      navOptions: [
        {
          imageName: initImgUrl("mine_post.png"),
          title: "我的发贴",
          routeName: "minePost",
        },
        // {
        //   imageName: initImgUrl("activity.png"),
        //   title: "我的活动",
        //   routeName: "",
        // },
        {
          imageName: initImgUrl("circle_manage.png"),
          title: "我管理的圈子",
          routeName: "mineCircle",
        },
        {
          imageName: initImgUrl("mine_browse.png"),
          title: "浏览记录",
          routeName: "mineBrowse",
        },
        {
          imageName: initImgUrl("mine_thumb.png"),
          title: "我的点赞",
          routeName: "mineThumb",
        },
        /*   {
             imageName: initImgUrl("mine_follow.png"),
             title: "我的关注",
             routeName: "mineFollow",
             smaller: true
           },*/
        {
          imageName: initImgUrl("mine_collect.png"),
          title: "我的收藏",
          routeName: "mineCollect",
        },
        {
          imageName: initImgUrl("mine_service.png"),
          title: "官方助手",
          routeName: "messageChatView",
        },
      ],
      userInfo: {},
      showActionSelect: false,
      actionsSelect: [
        {name: "更换头像"},
        {name: "设置头像框"}
      ],
      showAction: false,
      actions: [{name: "拍照"}, {name: "相册上传"}],
    };
  },
  created () {
    const {user_name, user_id} = this.$store.getters.user;
    this.userInfo = {user_name, user_id};
    this.getUserInfo(user_id);
  },
  methods: {
    // 获取个人信息
    async getUserInfo (id = '') {
      const res = await getUserById(id);
      const {deptStr, avatar} = res
      this.$set(this.userInfo, "deptStr", deptStr);
      this.$set(this.userInfo, "avatar", avatar);
    },
    // 路由跳转
    handleRoute ({routeName} = {}) {
      if (routeName === "messageChatView") {
        this.$router.push({
          name: "messageChatView",
          query: {userId: SUPER_ADMIN_ID, userName: ADMIN_NAME},
        });
      } else if (routeName === "mineCircle") {
        // 跳转我加入的圈子页面
        this.$router.push({name: "circleMy"});
        // 我的
        localStorage.setItem('type', 0)
        localStorage.setItem('myCircleType', '1')
      } else {
        this.$router.push({name: routeName});
      }
    },
    handleAvatarClick () {
      this.showActionSelect = true;
    },
    handleSelect (action, key) {
      if (key === 0) {
        this.showActionSelect = false;
        // this.showAction = true;
        this.handleAvatarSelect('', 1)
      } else if (key === 1) {
        this.$router.push({name: "mineAvatarFrame"});
      }
    },
    // 动作面板点击事件
    async handleAvatarSelect (action, key) {
      let files = {};
      if (key === 0) {
        files = await selectFile(true);
      } else if (key == 1) {
        files = await selectFile();
      }
      const filesRes = await this.$upload.multipleFilesUpload(files);
      await updateAvatar(filesRes[0].filename);
      let user = this.$store.getters.user;
      user.avatar = filesRes[0].filename;
      this.$store.commit("user/SET_USERINFO", user);
      await this.getUserInfo();
      this.showAction = false;
    },
    handleCreateTopic () {
      this.$router.push({name: "createTopic"});
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep {
  // 修改底部弹出层的底边距
  .van-action-sheet {
    padding-bottom: 0;
  }
}

.mine {
  background-color: #f6f6f6;
  height: 100vh;
  overflow-y: auto;

  .van-action-sheet {
    margin-bottom: 57px;
    color: $mainTextColor;
    background-color: transparent;
    font-size: 14px;

    .van-action-sheet__content {
      border-radius: 6px;
      width: 360px;
      margin: 0 auto;

      .van-action-sheet__item {
        background-color: $backgroundGray;
        border-bottom: 1px solid rgba(17, 17, 17, 0.5);
        height: 56px;
      }
    }

    .van-action-sheet__gap {
      background-color: transparent;
    }

    .van-action-sheet__cancel {
      border-radius: 6px;
      height: 56px;
      width: 360px;
      margin: 0 auto;
    }
  }
}
</style>

<template>
  <div class="banner">
    <img src="/images/mine/demon.png">
    <span class="text">去发声，与同事互动一下吧</span>
    <van-button round color="linear-gradient(to right, #DDE6FF, #F1F5FF)" @click="handleClick">
      去发帖
    </van-button>
  </div>
</template>

<script>
export default {
  name: "index",
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background-image: url("/images/mine/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 56px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 25px;
  margin: 0 15px;
  border-radius: 8px;

  img {
    width: 19px;
    margin-right: 10px;
  }

  .text {
    font-size: 14px;
    color: #fff;
    flex: 1;
  }

  .van-button {
    height: 25px;

    .van-button__content {
      color: #4A5778;
      font-size: 12px;
    }
  }
}
</style>

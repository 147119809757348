/**
 * todo 头像框模块
 */
import request from '@/utils/request'

/**
 * todo 获取用户得到的头像框列表
 */
export function getListUserAvatarFrame(params) {
  return request({
    url: '/api/zeekr-article-new/avatarFrameUser/listUserAvatarFrame',
    method: 'get',
    params
  })
}

/**
 * todo 切换用户使用中的头像框
 */
export function updateUserAvatarFrame(id) {
  return request({
    url: '/api/zeekr-article-new/avatarFrameUser/switchUsing',
    method: 'get',
    params: {
      avatarFrameUserId: id
    }
  })
}

/**
 * todo 是否有获得新的头像框
 */
export function getNewFlag(id) {
  return request({
    url: '/api/zeekr-article-new/avatarFrameUser/newFlag',
    method: 'get',
    params: {
      avatarFrameUserId: id
    }
  })
}

<template>
  <div class="info">
    <div class="info-text">
      <div class="name">{{ user_name }}</div>
      <div class="profession" v-if="deptStr.length > 0">{{ deptStr }}</div>
    </div>
    <div class="avatar-right">
      <AvatarFrame :isNewFlag="isNewFlag" class="avatar" :user-info="userDetail" @avatarClick="handleAvatarClick"></AvatarFrame>
      <!-- <img class="avatar" :src="avatar" @click="handleAvatarClick" />-->
      <div
        class="id-button"
        v-if="userDetail.isSwitchIdentity"
        @click="switchIdentity"
      >
        <span class="change-text">{{
            userDetail.isOfficialIdentity ? "切换普通号" : "切换官方号"
          }}</span>
        <img class="change-img" src="/images/mine/id_change.png" alt="change" />
      </div>
    </div>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'
import {mapGetters} from "vuex";
import {getNewFlag} from "@/api/avatarFrame";

export default {
  components: {AvatarFrame},
  props: {
    avatar: String,
    user_name: String,
    deptStr: {
      type: String,
      default: "",
    },
  },
  data(){
    return{
      isNewFlag: false // 是否有获得新的头像框
    }
  },
  computed: {
    ...mapGetters(["userDetail"]),
  },
  created () {
    this.getNewFlag()
  },
  methods: {
    handleAvatarClick() {
      this.$emit("click");
    },
    /**
     * 是否有获得新的头像框
     */
    async getNewFlag(){
      const res = await getNewFlag()
      console.log(res)
      this.isNewFlag = res
    },
    /**
     * 切换官方账号
     */
    switchIdentity() {
      this.$dialog
        .confirm({
          message: this.userDetail.isOfficialIdentity ? "确认切换普通账号吗?" : "确认切换官方账号吗?",
        })
        .then(() => {
          this.$router.replace("/login?loginType=10");
        })
        .catch(() => {
          // console.log("--1")
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-image: linear-gradient(#eef2fe, #f6f6f6);
  padding: 65px 0 24px;

  .info-text {
    .name {
      font-size: 28px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 6px;
    }

    .profession {
      font-size: 14px;
      border-radius: 12px;
      padding: 8px 0;
      color: #6d7278;
    }
  }

  .avatar-right {
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar {
      width: 80px;
      height: 80px;
    }

    .id-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 8px;
      padding: 7px 10px;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 16px;

      .change-text {
        font-size: 12px;
        color: #000000;
        line-height: 18px;
      }

      .change-img {
        width: 12px;
        height: 12px;
        margin-left: 2px;
      }
    }
  }
}
</style>

<template>
  <div class="option-list">
    <div v-for="(item, index) in options" :key="index" class="option-item" @click="handleClick(item)">
      <img :src="item.imageName" class="item-icon" :class="{smaller: item.smaller}" />
      <div class="item-title">{{ item.title }}</div>
      <img src="/images/mine/right-arrow.png" class="right-arrow" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick(item) {
      console.log(item)
      if (item.routeName) {
        this.$emit('click', item)
      } else {
        this.$notify({
          type: 'warning',
          message: '开发中，敬请期待!'
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.option-list {
  margin: 10px 15px 60px;
  background-color: #fff;
  border-radius: 8px;
  padding: 6px 0;

  .option-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #595959;
    margin-left: 16px;
    padding: 20px 20px 20px 0;

    .item-icon {
      width: 20px;
      margin-right: 16px;

      &.smaller {
        width: 18px;
        margin-left: 2px;
      }
    }

    .item-title {
      flex: 1;
      text-align: left;
    }

    .right-arrow {
      width: 14px;
    }
  }
}

</style>
